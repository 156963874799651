<template>
  <v-container>
    <v-row class="mt-4">
      <v-col>
        <v-btn
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="primary"
            medium
            to="/customers"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h1>{{ this.$route.params.id }}</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>